<div class="border-b border-black">
  <section class="pt-2 pb-8 bloc-header container-bloc">
    <div class="">
      <div class="links">
        <a class="help" href="#">Centre d'aide</a>
        <a class="lang" href="#">Français</a>
      </div>

      <div class="header">
        <div class="left">
          <img class="logo" src="assets/webportal/img/logo.png" alt="logo" />
          <ul class="menu ml-16">
            <li class="active" id="home">
              <a routerLink="/portal/home">Accueil</a>
            </li>
            <li id="features">
              <a routerLink="/portal/features">Fonctionnalités</a>
            </li>
            <li id="price"><a routerLink="/portal/price">Nos prix</a></li>
            <li id="partner">
              <a routerLink="/portal/partner">Nos partenaires</a>
            </li>
            <li id="about"><a routerLink="/portal/about-us">A propos</a></li>
          </ul>
        </div>

        <div class="right">
          <a
            class="text-white bg-[#761941] border-0 focus:outline-none hover:bg-[#1D2424] rounded-[15px] px-12 py-4 font-semibold hover:text-white hover:cursor-pointer transition-all"
          >
            Nous rejoindre
          </a>
        </div>
      </div>
    </div>
  </section>
</div>


